<template>
  <div>
    <div class="mb-4">
      <v-card class="pa-6 mx-0">
        <v-row class="justify-space-between align-center">
          <v-col
            md="4"
            xl="4"
            cols="12"
            sm="12"
          >
            <h2>Buku Gratis Harian</h2>
            <small style="white-space: normal;">Halaman untuk menambahkan buku gratis harian</small>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div>
      <v-row
        v-if="freeBooks.length || !isLoadingData"
        class="align-center"
      >
        <v-col

          md="4"
          cols="12"
        >
          <a
            class="text-decoration-none"
            @click.prevent="showFormAdd()"
          >
            <v-card
              height="290"
              color="#F5F5F5"
              style="border: 1px dashed #c4c4c4;"
              class="d-flex align-center justify-center"
            >
              <div class="text-center">
                <v-icon size="40">
                  {{ icons.mdiPlus }}
                </v-icon>
                <p>Tambah Buku Gratis Harian</p>
              </div>
            </v-card>
          </a>
        </v-col>
        <v-col
          v-for="(dailyFreeBook, index) in freeBooks"
          :key="index"
          md="4"
          cols="12"
        >
          <v-card
            height="290"
          >
            <v-card
              class="overflow-hidden"
              style="z-index: 0"
              color="#AC268F"
            >
              <v-card-title
                class="pa-2 position-absolute"
                style="right: 0; z-index: 1;"
              >
                <v-spacer></v-spacer>
                <v-menu
                  offset-y
                  nudge-bottom="5"
                  nudge-left="60"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#b6b6b6"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <a @click.prevent="showFormEdit(dailyFreeBook.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/edit.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2">Edit</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                    <a @click.prevent="confirmDestroy(dailyFreeBook.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex btn-delete">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/trash-red.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2 text-delete">Hapus</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
              </v-card-title>
              <div
                style="z-index: 0;"
              >
                <v-img
                  :src="dailyFreeBook.library.thumbnail"
                  height="150"
                ></v-img>
              </div>
            </v-card>
            <v-card-text class="py-4">
              <div class="mb-4">
                <h2 class="font-weight-bold text-truncate mb-2">
                  {{ dailyFreeBook.library.name }}
                </h2>
              </div>

              <div class="d-flex">
                <div class="me-2 mb-2">
                  <v-img
                    src="@/assets/icons/calendar.svg"
                    sizes="20"
                  ></v-img>
                </div>
                <span class="font-weight-light">{{ dailyFreeBook.show_date }} </span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          v-for="i in 3"
          :key="i"
          md="4"
        >
          <v-skeleton-loader
            type="card"
            :loading="true"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Buku Gratis Harian': 'Edit Buku Gratis Harian'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-autocomplete
            v-model="freeBook.library.uuid"
            label="Cari Buku"
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :items="libraries"
            :loading="isLoadingLib"
            :disabled="isLoadingLib"
            :search-input.sync="searchBook"
            hide-no-data
            no-filter
          ></v-autocomplete>
        </div>
        <div>
          <v-menu
            v-model="show_date"
            :close-on-content-click="true"
            nudge-right="0"
            nudge-top="20"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="freeBook.show_date"
                label="Tanggal Upload"
                readonly
                outlined
                dense
                v-bind="attrs"
                :loading="isLoadingData"
                :disabled="isLoadingData"
                v-on="on"
                @click="autofillDate"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="freeBook.show_date"
              :first-day-of-week="0"
              locale="id-id"
              :min="new Date().toISOString().substr(0, 10)"
              color="primary"
            ></v-date-picker>
          </v-menu>
        </div>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
      persistent
      :width="600"
      :is-loading-button="isLoadingButton"
      header="Hapus Buku Gratis Harian"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiPlus } from '@mdi/js'
import ConfirmDialog from '../components/ConfirmDialog.vue'
import ModalDialog from '../components/ModalDialog.vue'

export default {
  name: 'DailyFreeBook',
  components: {
    ConfirmDialog,
    ModalDialog,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiDotsVertical,
      },
      componentKey: 0,
      componentKey1: 0,
      search: '',
      searchBook: null,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      modalDialog: false,
      confirmDialog: false,
      formValid: false,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      width: 600,
      dialog: '',
      headers: [
        { text: 'Nama Buku', value: 'library.name' },
        { text: 'Tanggal', value: 'show_date' },
        { text: 'Actions', value: 'actions' },
      ],
      freeBooks: [],
      freeBook: {
        library: {
          uuid: null,
        },
        show_date: '',
      },
      show_date: '',
      libraries: [],
      filterQuery: {
        search: '',
      },
      selectedPacket: {},
      serviceFreeBook: 'dailybook',
      serviceLibrary: 'library',
      componentValid: false,
      states: [
        'Alabama',
        'Alaska',
        'American Samoa',
        'Arizona',
        'Arkansas',
        'California',
      ],
      isLoadingLib: false,
    }
  },
  computed: {},
  watch: {
    page: {
      handler() {
        this.listFreeBook(this.filterQuery)
      },
    },
    freeBook: {
      handler() {
        const valid = []
        const requiredField = ['library_uuid', 'show_date']
        Object.entries(this.freeBook).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    searchBook: {
      handler(val) {
        if (this.searchBook.length > 3) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.page = 1
            this.searchBooks(this.searchBook)
          }, 500)
        }
      },
    },
  },
  created() {
    this.listFreeBook()
  },
  async mounted() {
    if (this.searchBook !== null) {
      await this.searchBooks(this.searchBook)
    }
  },
  methods: {
    autofillDate() {
      this.freeBook.show_date = new Date().toISOString().substr(0, 10)
    },

    async listFreeBook(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.serviceFreeBook, { ...params, page: this.page }).then(
        ({ data }) => {
          this.freeBooks = data.data.map((freeBook, index) => ({
            ...freeBook,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },

    async searchBooks(val) {
      this.isLoadingLib = true
      await this.$services.ApiServices.list(this.serviceLibrary, {
        library: val,
      }).then(
        ({ data }) => {
          this.libraries = data.data
        },
        err => {
          this.isLoadingLib = false
          console.error(err)
        },
      )
      this.isLoadingLib = false
    },

    // async listLibrary(params = {}) {
    //   await this.$services.ApiServices.list(this.serviceLibrary, {
    //     ...params,
    //     per_page: 'all',
    //   }).then(
    //     ({ data }) => {
    //       this.libraries = data.data
    //     },
    //     err => {
    //       console.error(err)
    //     },
    //   )
    // },

    async add() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add(this.serviceFreeBook, { library_uuid: this.freeBook.library.uuid, show_date: this.freeBook.show_date }).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.resetForm()
          this.listFreeBook(this.filterQuery)
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
      this.resetForm()
      this.listFreeBook(this.filterQuery)
      this.modalDialog = false
    },

    async update() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update(this.serviceFreeBook, { library_uuid: this.freeBook.library.uuid, show_date: this.freeBook.show_date }, this.freeBook.uuid).then(({ data }) => {
        this.showSnackbar({
          text: data.result.message,
          color: 'success',
        })
        this.isLoadingButton = false
        this.resetForm()
        this.listFreeBook(this.filterQuery)
        this.modalDialog = false
      })
      this.isLoadingButton = false
      this.resetForm()
      this.listFreeBook(this.filterQuery)
      this.modalDialog = false
    },

    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.freeBook.uuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.serviceFreeBook, this.freeBook.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          this.listFreeBook(this.filterQuery)
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.confirmDialog = false
    },

    disabledComponent(formValid) {
      this.componentValid = formValid
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.resetForm()
      this.modalDialog = true
      this.isLoadingData = true
      await this.$services.ApiServices.get(this.serviceFreeBook, uuid).then(({ data }) => {
        this.freeBook = data.data
        this.freeBook.library.uuid = data.data.library.uuid
        this.isLoadingButton = false
      })
      this.isLoadingData = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },

    handleCloseDialog() {
      this.modalDialog = false
      this.dialog = ''
      this.resetForm()
      this.listFreeBook(this.filterQuery)
    },

    searchHandler(data) {
      this.filterQuery.search = data
      this.page = 1
      this.listFreeBook(this.filterQuery)
    },

    resetForm() {
      this.freeBook = {
        library: {
          uuid: null,
        },
        show_date: '',
      }
    },
  },
}
</script>

<style>
.text-delete {
  color: #e30000 !important;
}
</style>
